import React from 'react';
import MainLayout from '../components/layouts/MainLayout';
import products from '../components/menu/product/testProducts';
import TotalProductPage from '../components/productpage/TotalProductPage';
import GlobalStyles from '../styles/GlobalStyles';

export default function ProductPage({})
{
    return(
        <MainLayout>
            <GlobalStyles/>
            <TotalProductPage
            />
        </MainLayout>
    )
}