const products = [
    {
        "id": "61576ff7d6ad75000131e024",
        "name": "Lavender Chamomile CBD 3:1 510 Thread Cartridge | 0.5g",
        "description": "Our 3:1 Lavender Chamomile vapes are recognizably aromatic and gentle, with a high dose of CBD and just enough THC for that extra “push”\n",
        "brand_name": "Floresense",
        "category": "VAPORIZERS",
        "subcategory": "CARTRIDGES",
        "image": "https://images.dutchie.com/2755cf8af35df904748a13b78c589ab4",
        "slug": "floresense-lavender-chamomile-cbd-31-510-thread-cartridge-or-05g",
        "strain_type": "Hybrid",
        "min_cbd": 51,
        "min_thc": 20.5,
        "price": 23.85,
        "thc": "20.5% - 26.5%",
        "cbd": "51% - 57%",
        "effects": [
          "CALM",
          "HAPPY",
          "RELAXED"
        ],
        "stores": [
          "The House of Cannabis - Barrie",
          "The House of Cannabis - Chinatown",
          "The House of Cannabis - Old Town",
          "The House of Cannabis - Keswick"
        ],
        "weights": [
          ".5g"
        ],
        "store_ids": [
          "882f4295-2c51-4441-97ee-762a8b9e3478",
          "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae",
          "5227c5c1-5c0c-4a7b-8f73-294ee17b0fa8",
          "0b537b1d-bf7d-4812-a08c-889286c5fe02"
        ],
        "store_addys": [
          "66 Dunlop St E, Barrie, ON L4M 1A4, Canada",
          "404 Spadina Ave, Toronto, ON M5T 2G7, Canada",
          "244 King St E, Toronto, ON M5A 1K1, Canada",
          "1 Church Street, Keswick, ON L4P 3E9, Canada"
        ],
        "variants": [
          {
            "option": ".5g",
            "price_med": 23.85,
            "price_rec": 23.85,
            "special_price_med": null,
            "special_price_rec": 18.36
          }
        ],
        "ids": [
          {
            "id": "61576ff7d6ad75000131e024",
            "retailerId": "882f4295-2c51-4441-97ee-762a8b9e3478"
          },
          {
            "id": "6158f107eb589c0001664c43",
            "retailerId": "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae"
          },
          {
            "id": "6157b7b572aca700015da00c",
            "retailerId": "5227c5c1-5c0c-4a7b-8f73-294ee17b0fa8"
          },
          {
            "id": "615898d1283aa50001efeb3e",
            "retailerId": "0b537b1d-bf7d-4812-a08c-889286c5fe02"
          }
        ],
        "variants_arr": [
          {
            "retailerId": "882f4295-2c51-4441-97ee-762a8b9e3478",
            "variants": [
              {
                "option": ".5g",
                "price_med": 23.85,
                "price_rec": 23.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae",
            "variants": [
              {
                "option": ".5g",
                "price_med": 23.85,
                "price_rec": 23.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "5227c5c1-5c0c-4a7b-8f73-294ee17b0fa8",
            "variants": [
              {
                "option": ".5g",
                "price_med": 23.85,
                "price_rec": 23.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "0b537b1d-bf7d-4812-a08c-889286c5fe02",
            "variants": [
              {
                "option": ".5g",
                "price_med": 23.85,
                "price_rec": 23.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          }
        ]
    },
    {
        "id": "61576ff7d6ad75000131e023",
        "name": "Hawaii Heartbreak Pre-Roll 7-pack | 3.5g",
        "description": "Hawaii Heartbreak is an indica-dominant strain with light green tones with hints of orange hairs. Hawaii Heartbreak's dominant aroma is floral with a hint of diesel that is both flavourful and well-rounded with an earthy herbal base.\n",
        "brand_name": "RIFF",
        "category": "PRE_ROLLS",
        "subcategory": "SINGLES",
        "image": "https://images.dutchie.com/6774ceff48992bf2d7f9bc7b4e91ab80",
        "slug": "riff-hawaii-heartbreak-pre-roll-7-pack-or-35g",
        "strain_type": "Indica",
        "min_cbd": 1,
        "min_thc": 17,
        "price": 36.85,
        "thc": "17% - 23%",
        "cbd": "1%",
        "effects": [
          "HAPPY",
          "RELAXED",
          "SLEEPY"
        ],
        "stores": [
          "The House of Cannabis - Barrie",
          "The House of Cannabis - Chinatown",
          "The House of Cannabis - Old Town",
          "The House of Cannabis - Keswick"
        ],
        "weights": [
          "3.5g"
        ],
        "store_ids": [
          "882f4295-2c51-4441-97ee-762a8b9e3478",
          "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae",
          "5227c5c1-5c0c-4a7b-8f73-294ee17b0fa8",
          "0b537b1d-bf7d-4812-a08c-889286c5fe02"
        ],
        "store_addys": [
          "66 Dunlop St E, Barrie, ON L4M 1A4, Canada",
          "404 Spadina Ave, Toronto, ON M5T 2G7, Canada",
          "244 King St E, Toronto, ON M5A 1K1, Canada",
          "1 Church Street, Keswick, ON L4P 3E9, Canada"
        ],
        "variants": [
          {
            "option": "3.5g",
            "price_med": 36.85,
            "price_rec": 36.85,
            "special_price_med": null,
            "special_price_rec": null
          }
        ],
        "ids": [
          {
            "id": "61576ff7d6ad75000131e023",
            "retailerId": "882f4295-2c51-4441-97ee-762a8b9e3478"
          },
          {
            "id": "615f869d74467600012f6cb4",
            "retailerId": "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae"
          },
          {
            "id": "6157b7b572aca700015da00b",
            "retailerId": "5227c5c1-5c0c-4a7b-8f73-294ee17b0fa8"
          },
          {
            "id": "615898d1283aa50001efeb3c",
            "retailerId": "0b537b1d-bf7d-4812-a08c-889286c5fe02"
          }
        ],
        "variants_arr": [
          {
            "retailerId": "882f4295-2c51-4441-97ee-762a8b9e3478",
            "variants": [
              {
                "option": "3.5g",
                "price_med": 36.85,
                "price_rec": 36.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae",
            "variants": [
              {
                "option": "3.5g",
                "price_med": 36.85,
                "price_rec": 36.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "5227c5c1-5c0c-4a7b-8f73-294ee17b0fa8",
            "variants": [
              {
                "option": "3.5g",
                "price_med": 36.85,
                "price_rec": 36.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "0b537b1d-bf7d-4812-a08c-889286c5fe02",
            "variants": [
              {
                "option": "3.5g",
                "price_med": 36.85,
                "price_rec": 36.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          }
        ]
    },
    {
        "id": "61293a583b782c00010a041a",
        "name": "Animal Face Pre-Roll 3-pack | 1.5g",
        "description": "Bred by Seed Junky Genetics in California, Animal Face is an ultra-potent, flavourful sativa dominant hybrid created through crossing the Face Off OG clone and Animal Mints male. Carmel Cannabis' version of Animal Face was selected out of 100 different phenos, not only for its high potency and terpene profile, but also because of its unique flavours of lemon and cake. \n\nBecause it’s just the right thing to do, all Carmel Cannabis flower is hang dried, hand trimmed, slow cold cured, and hand packaged. They believe fresh and terpy is best, so all of their flower is packaged in a nitrogen flushed pouch that features a perfect airtight seal.\n\nNon irradiated, obviously. \n\nContains three 0.5g pre-rolls.\n",
        "brand_name": "Carmel Cannabis",
        "category": "PRE_ROLLS",
        "subcategory": "SINGLES",
        "image": "https://images.dutchie.com/17819749868f8b7fc8509a7ee72502fe",
        "slug": "carmel-cannabis-animal-face-pre-roll-3-pack-or-15g",
        "strain_type": "Sativa",
        "min_thc": 20,
        "price": 21.85,
        "thc": "20% - 26%",
        "effects": [
          "ENERGETIC",
          "HAPPY",
          "CREATIVE",
          "FOCUSED",
          "INSPIRED"
        ],
        "stores": [
          "The House of Cannabis - Barrie",
          "The House of Cannabis - Chinatown",
          "The House of Cannabis - Kitchener",
          "The House of Cannabis - Keswick"
        ],
        "weights": [
          "1.5g"
        ],
        "store_ids": [
          "882f4295-2c51-4441-97ee-762a8b9e3478",
          "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae",
          "0aae4e48-dfef-49c6-b0f1-ce9d43bc23ba",
          "0b537b1d-bf7d-4812-a08c-889286c5fe02"
        ],
        "store_addys": [
          "66 Dunlop St E, Barrie, ON L4M 1A4, Canada",
          "404 Spadina Ave, Toronto, ON M5T 2G7, Canada",
          "181 King St W, Kitchener, ON N2G 1A7, Canada",
          "1 Church Street, Keswick, ON L4P 3E9, Canada"
        ],
        "variants": [
          {
            "option": "1.5g",
            "price_med": 21.85,
            "price_rec": 21.85,
            "special_price_med": null,
            "special_price_rec": null
          }
        ],
        "ids": [
          {
            "id": "61293a583b782c00010a041a",
            "retailerId": "882f4295-2c51-4441-97ee-762a8b9e3478"
          },
          {
            "id": "60cfb7652058870001af068b",
            "retailerId": "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae"
          },
          {
            "id": "60d619832c8a640001c9441f",
            "retailerId": "0aae4e48-dfef-49c6-b0f1-ce9d43bc23ba"
          },
          {
            "id": "6129874bccc87e0001e32370",
            "retailerId": "0b537b1d-bf7d-4812-a08c-889286c5fe02"
          }
        ],
        "variants_arr": [
          {
            "retailerId": "882f4295-2c51-4441-97ee-762a8b9e3478",
            "variants": [
              {
                "option": "1.5g",
                "price_med": 21.85,
                "price_rec": 21.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "3e0113ad-91a0-43ce-bbe5-f8428d8f8aae",
            "variants": [
              {
                "option": "1.5g",
                "price_med": 21.85,
                "price_rec": 21.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "0aae4e48-dfef-49c6-b0f1-ce9d43bc23ba",
            "variants": [
              {
                "option": "1.5g",
                "price_med": 21.85,
                "price_rec": 21.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          },
          {
            "retailerId": "0b537b1d-bf7d-4812-a08c-889286c5fe02",
            "variants": [
              {
                "option": "1.5g",
                "price_med": 21.85,
                "price_rec": 21.85,
                "special_price_med": null,
                "special_price_rec": null
              }
            ]
          }
        ]
    }
]

module.exports = products;